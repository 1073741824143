import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'dat.gui'
import { GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js'
import { SkeletonUtils} from 'three/examples/jsm/utils/SkeletonUtils.js'
import deathzoneVertexShader from './shaders/deathzone/vertex.glsl'
import deathzoneFragmentShader from './shaders/deathzone/fragment.glsl'
import { Float16BufferAttribute } from 'three'

/**
 * Base
 */
// Debug
const gui = new dat.GUI({width: 325})
gui.close()

// Canvas and useful globals
const canvas = document.querySelector('canvas.webgl')
const objects = []
const mixers = []
const values = []
const coords = []
const tokenTypes = {
    CHECKER: 'checker',
    FOX: 'fox'
}
let mouseObject

// Parameters
const parameters = {
    gridSize: 8,
    planeSize: 100,
    openRows: 1,
    gridColor: 0x111111,
    clickScale: 0.99,
    backgroundColor: '#f0f0f0',
    rollOverMaterialColor: '#ff0000',
    rollOverMaterialOpacity: 0.5,
    playerOneCheckColor: '#353535',
    playerTwoCheckColor: '#B80F0A',
    directionalLightColor: '#ffffff',
    reskinPlayerOne: true,
    reskinPlayerTwo: true,
    playerOneToken: tokenTypes.CHECKER,
    playerTwoToken: tokenTypes.CHECKER
}
parameters.cubeSize = parameters.planeSize / parameters.gridSize


/**
 * Window Events
 */

// Aspect Ratio Fixer
const onWindowResize = () => {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();

    renderer.setSize( window.innerWidth, window.innerHeight );
}

// Move Event
const onPointerMove = (event) => {
    pointer.set( 
          (event.clientX / window.innerWidth) * 2 - 1,
        -(event.clientY / window.innerHeight) * 2 + 1
    )

    // Update raycaster
    raycaster.setFromCamera(pointer, camera)

    // Check for intersects
    const intersects = raycaster.intersectObjects(objects)
    if ( intersects.length > 0 ) {
        const intersect = intersects[ 0 ]
        if (intersect.object === deathPlaneBlack || 
            intersect.object === deathPlaneRed) {
            
            rollOverMesh.position.copy(intersect.object.position)
            rollOverMesh.position.y = parameters.cubeSize/2
            if (mouseObject) {
                mouseObject.position.copy(intersect.object.position)
                mouseObject.position.y = parameters.cubeSize/2
            }
        } else {
            rollOverMesh.position
                .copy(intersect.point)
                .add(intersect.face.normal)
            rollOverMesh.position
                .divideScalar(parameters.cubeSize)
                .floor()
                .multiplyScalar(parameters.cubeSize)
                .addScalar(parameters.cubeSize / 2)
            rollOverMesh.visible = true
            if (mouseObject) {
                mouseObject.position
                    .copy(intersect.point)
                    .add(intersect.face.normal)
                mouseObject.position
                    .divideScalar(parameters.cubeSize)
                    .floor()
                    .multiplyScalar(parameters.cubeSize)
                    .addScalar(parameters.cubeSize / 2)
            }
        }
    }
}

// Click Event
const onPointerDown = (event) => {
    // bit of a hack to make it work well on mobile where
    // pointerDown might be fired without a mouse move event preceeding it
    onPointerMove(event)

    // check if we've already got an item selected
    if (mouseObject) {
        // set the object's y position to be 10 offset by the number of items already in that tile
        mouseObject.position.y = mouseObject.startingY
        for (let i = 0; i < objects.length; i++) {
            if( objects[i].position.x === mouseObject.position.x &&
                objects[i].position.z === mouseObject.position.z &&
                objects[i] !== mouseObject &&
                objects[i] !== deathPlaneBlack && objects[i] !== deathPlaneRed) {
                mouseObject.position.y += objects[i].ySpacing
            }
        }
        // reset scale
        mouseObject.scale.set(
            mouseObject.scale.x*(2-parameters.clickScale),
            mouseObject.scale.y*(2-parameters.clickScale),
            mouseObject.scale.z*(2-parameters.clickScale)
        )
        mouseObject = null
        console.log('putting object down')
    } else {
        pointer.set((event.clientX / window.innerWidth) * 2 - 1, - ( event.clientY / window.innerHeight ) * 2 + 1 )
        raycaster.setFromCamera(pointer, camera)
        const intersects = raycaster.intersectObjects(objects, true)

        if (intersects.length > 0) {
            const intersect = intersects[0]
            if (intersect.object.isGrabbable) {
                // pick up object, bit of a hack because of the differences between the fox and the checker models
                mouseObject = intersect.object.parent.type === "Group" ? intersect.object.parent : intersect.object.parent.parent
                // shrink scale to avoid shader conflicts
                mouseObject.scale.set(
                    mouseObject.scale.x*parameters.clickScale,
                    mouseObject.scale.y*parameters.clickScale,
                    mouseObject.scale.z*parameters.clickScale)
                console.log('picking object up')
                onPointerMove(event)
            }
        }
    } 
}

// Event Listeners
document.addEventListener('pointermove', onPointerMove)
document.addEventListener('pointerdown', onPointerDown)

window.addEventListener('resize', onWindowResize)
/*/ Window Events */

/** 
 * Helper functions
 */
const gltfCloner = (gltf) => {
    let clone = new THREE.Object3D(gltf)
    clone.scene = SkeletonUtils.clone(gltf.scene)
    clone.startingY = gltf.startingY
    clone.ySpacing = gltf.ySpacing

    if (gltf.animations.length > 0) {
        let mix = new THREE.AnimationMixer(clone.scene)
        let action = mix.clipAction(gltf.animations[0])
        mixers.push(mix)
        action.play()
    }
    return(clone)
}

const updateCords = () => {
    values.splice(0, values.length)
    coords.splice(0, coords.length)
    let scalarAdjustment = parameters.cubeSize * 0.5

    for (let i = 0; i < parameters.gridSize * 0.5; i++){
        values.push((parameters.cubeSize * i) + scalarAdjustment)
    }

    /**
     *  _ _ _ _ _ _ _ _
     * | X:-SA | X: SA |
     * | Z:-SA | Z:-SA |
     *  ---------------
     * | X:-SA | X: SA |
     * | Z: SA | Z: SA |
     *  ‾ ‾ ‾ ‾ ‾ ‾ ‾ ‾
     **/
    for (let i = parameters.openRows; i < values.length; i++) {
        for (let j = i%2; j < values.length; j=j+2) {
            // x, z
            coords.push([values[values.length-1-j], values[i]])
            // -x, -z
            coords.push([-values[values.length-1-j], -values[i]])
            // x, -z
            coords.push([values[j], -values[i]])
            // -x, z
            coords.push([-values[j], values[i]])
        }
    }
}

const fillTheBoard = () => {
    updateCords()
    for (let i = 0; i < coords.length; i++){
        let clone
        let material

        let applyMaterial 
        // player 1 topkens
        if (coords[i][1] > 0) {
            switch (parameters.playerOneToken) {
                case tokenTypes.CHECKER:
                    clone = gltfCloner(checkerModel)
                    break
                case tokenTypes.Fox:
                default:
                    clone = gltfCloner(foxModel)
                    break
            }
            clone.scene.rotation.y += Math.PI
            material = playerOneMaterial
            applyMaterial = parameters.reskinPlayerOne
        } else {
            // player 2 tokens
            switch (parameters.playerTwoToken) {
                case tokenTypes.CHECKER:
                    clone = gltfCloner(checkerModel)
                    break
                case tokenTypes.Fox:
                default:
                    clone = gltfCloner(foxModel)
                    break
            }
            material = playerTwoMaterial
            applyMaterial = parameters.reskinPlayerTwo
        }

        clone.scene.position.set(coords[i][0], clone.startingY, coords[i][1])
        clone.scene.traverse((o) => {
            if (applyMaterial && o.isMesh) o.material = material
            // useful for movement/mouse interactions
            o.isGrabbable = true
            o.startingY = clone.startingY
            o.ySpacing = clone.ySpacing
        })
        scene.add( clone.scene )
        objects.push( clone.scene )
    }
}
/*/ Helper functions */

// Camera
const camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 10000)
camera.position.set(parameters.planeSize*.8, parameters.planeSize*.8, parameters.planeSize*2.25)
camera.lookAt(0, 0, 0)

// Controls
const orbitControls = new OrbitControls(camera, canvas)
orbitControls.enableDamping = true

// Scene
const scene = new THREE.Scene()
scene.background = new THREE.Color(parameters.backgroundColor)

const sceneFolderGui = gui.addFolder('scene')
sceneFolderGui.addColor(parameters, 'backgroundColor')
    .name('background color')
    .onChange(() => {
        scene.background = new THREE.Color(parameters.backgroundColor)
        rollOverMaterial.color.set(parameters.rollOverMaterialColor)
    })

// Roll-over Cube
const rollOverGeo = new THREE.BoxGeometry(parameters.cubeSize, parameters.cubeSize, parameters.cubeSize)
const rollOverMaterial = new THREE.MeshBasicMaterial({
    color: parameters.rollOverMaterialColor,
    opacity: parameters.rollOverMaterialOpacity,
    transparent: true
})

// start with it invisible because having a red square sitting in the middle of the scene is stupid
const rollOverMesh = new THREE.Mesh(rollOverGeo, rollOverMaterial)
rollOverMesh.visible = false

sceneFolderGui.add(rollOverMaterial, 'opacity').min(0).max(1).step(0.01).name('rollover opacity')
sceneFolderGui.addColor(parameters, 'rollOverMaterialColor')
    .name('rollover color')
    .onChange(() => {
        rollOverMaterial.color.set(parameters.rollOverMaterialColor)
    })
    sceneFolderGui.add(parameters, 'planeSize').min(10).max(1000).step(5).name('plane size').onChange(() => { buildScene() })
    sceneFolderGui.add(parameters, 'gridSize').min(2).max(30).step(2).name('grid size !! CAREFUL !!').onChange(() => { buildScene() })
    sceneFolderGui.add(parameters, 'openRows').min(0).max(parameters.gridSize).step(1).name('open rows').onChange(() => { buildScene() })

/**
 *  Models and Materials
 */
let foxModel = null, checkerModel = null

// Checker Materials
const playerOneMaterial = new THREE.MeshPhongMaterial({
    color: parameters.playerOneCheckColor,
    flatShading: true })
const playerTwoMaterial = new THREE.MeshPhongMaterial({
    color: parameters.playerTwoCheckColor,
    flatShading: true })

const tokenFolder = gui.addFolder('tokens')
tokenFolder.add(parameters, 'reskinPlayerOne').name('reskin player one').onChange(() => { buildScene() })
tokenFolder.addColor(parameters, 'playerOneCheckColor')
    .name('player one color')
    .onChange(() => {
        playerOneMaterial.color.set(parameters.playerOneCheckColor)
    })
tokenFolder.add(parameters, 'playerOneToken', [ tokenTypes.CHECKER, tokenTypes.FOX ])
    .name('player one token')
    .onChange(() => { buildScene() })

tokenFolder.add(parameters, 'reskinPlayerTwo').name('reskin player two').onChange(() => { buildScene() })
tokenFolder.addColor(parameters, 'playerTwoCheckColor')
    .name('player two color')
    .onChange(() => {
        playerTwoMaterial.color.set(parameters.playerTwoCheckColor)
    })
tokenFolder.add(parameters, 'playerTwoToken', [ tokenTypes.CHECKER, tokenTypes.FOX ])
    .name('player two token')
    .onChange(() => { buildScene() })

// GLTF Loader
const gltfLoader = new GLTFLoader()
// Model Loader
gltfLoader.load(
    document.URL.substr(0,document.URL.lastIndexOf('/')) +'/models/checker_piece.gltf',
    (gltf) => {
        checkerModel = gltf
        console.log('checker loaded')
        // load the scene if the other thing is loaded
        if(foxModel !== null) {buildScene()}
    },
    (progress) => {
        console.log('gltf load in progress')
    },
    (error) => {
        console.log('gltf error:')
        console.log(error)
    }
)
gltfLoader.load(
    document.URL.substr(0,document.URL.lastIndexOf('/')) +'/models/Fox/glTF/Fox.gltf',
    (gltf) => {
        foxModel = gltf
        console.log('fox loaded')
        // load the scene if the other thing is loaded
        if(checkerModel !== null) {buildScene()}
    },
    (progress) => {
        console.log('gltf load in progress')
    },
    (error) => {
        console.log('gltf error:')
        console.log(error)
    }
)
/* Models and Materials */

/**
 * Game board things
 */

// Grid Plane
let geometry = new THREE.PlaneGeometry(parameters.planeSize, parameters.planeSize)
geometry.rotateX(-Math.PI/2)
const plane = new THREE.Mesh(geometry, new THREE.MeshBasicMaterial({visible: false}))
    
// Death Zone Planes
const deathzoneMaterial = new THREE.ShaderMaterial({
    vertexShader: deathzoneVertexShader,
    fragmentShader: deathzoneFragmentShader,
    transparent: true,
    side: THREE.DoubleSide,
    uniforms: {
        uOutlineThickness: { value: 0.01 },
        uRGB: { value: new THREE.Color(parameters.gridColor) }
    }
})
const deathGeometry = new THREE.PlaneGeometry(parameters.cubeSize, parameters.cubeSize)
deathGeometry.rotateX(-Math.PI/2)

const deathPlaneRed = new THREE.Mesh(deathGeometry, deathzoneMaterial)
const deathPlaneBlack = new THREE.Mesh(deathGeometry, deathzoneMaterial)

// Grid
let gridHelper = new THREE.GridHelper(
    parameters.planeSize,
    parameters.gridSize, 
    parameters.gridColor,
    parameters.gridColor)

sceneFolderGui.addColor(parameters, 'gridColor')
    .name('grid color')
    .onChange(() => {
        // replace gridHelper
        scene.remove(gridHelper)
        gridHelper = new THREE.GridHelper(
            parameters.planeSize,
            parameters.gridSize, 
            parameters.gridColor,
            parameters.gridColor)
        scene.add(gridHelper)

        // Update death planes
        deathzoneMaterial.uniforms.uRGB.value.set(parameters.gridColor)
    })
/** Game board */

/**
 * Lights
 */
// Lights
const ambientLight = new THREE.AmbientLight(0x606060)

const directionalLight = new THREE.DirectionalLight(parameters.directionalLightColor)
directionalLight.position.set(1, 0.75, 0.5).normalize()

let directionalLightFolder = gui.addFolder('directional light')
directionalLightFolder.add(directionalLight, 'intensity').min(0).max(1).step(0.001)
directionalLightFolder.add(directionalLight.position, 'x').min(- 5).max(5).step(0.001)
directionalLightFolder.add(directionalLight.position, 'y').min(- 5).max(5).step(0.001)
directionalLightFolder.add(directionalLight.position, 'z').min(- 5).max(5).step(0.001)
directionalLightFolder.addColor(parameters, 'directionalLightColor')
    .name('color')
    .onChange(() => {
        directionalLight.color.set(parameters.directionalLightColor)
    })

// Raycaster
const raycaster = new THREE.Raycaster()
const pointer = new THREE.Vector2()

// Renderer
const renderer = new THREE.WebGLRenderer({ 
    canvas: canvas,
    antialias: true 
})
renderer.setPixelRatio(window.devicePixelRatio)
renderer.setSize(window.innerWidth, window.innerHeight)

const buildScene = () => {
    console.log('building scene')
    parameters.cubeSize = parameters.planeSize / parameters.gridSize

    // Be a little smaller on the x and z so we don't run into the grid borders
    let foxScaler = .007
    foxModel.scene.scale.set(parameters.cubeSize*foxScaler, parameters.cubeSize*foxScaler, parameters.cubeSize*foxScaler)
    foxModel.startingY = 0
    foxModel.ySpacing = parameters.cubeSize *.425

    let checkerScaler = .9
    checkerModel.scene.scale.set(parameters.cubeSize*checkerScaler, parameters.cubeSize, parameters.cubeSize*checkerScaler)
    checkerModel.startingY = parameters.cubeSize *.075
    checkerModel.ySpacing = parameters.cubeSize *.15

    rollOverMesh.scale.set(
        parameters.cubeSize/rollOverMesh.geometry.parameters.width,
        parameters.cubeSize/rollOverMesh.geometry.parameters.depth,
        parameters.cubeSize/rollOverMesh.geometry.parameters.height 
    )

    plane.scale.set(
        parameters.planeSize/plane.geometry.parameters.width,
        1,
        parameters.planeSize/plane.geometry.parameters.height
    )

    deathPlaneRed.scale.set(
        parameters.cubeSize/deathPlaneRed.geometry.parameters.width,
        1,
        parameters.cubeSize/deathPlaneRed.geometry.parameters.height
    )

    deathPlaneBlack.scale.set(
        parameters.cubeSize/deathPlaneRed.geometry.parameters.width,
        1,
        parameters.cubeSize/deathPlaneRed.geometry.parameters.height
    )

    deathPlaneRed.position.x = parameters.planeSize * 0.5 + parameters.cubeSize
    deathPlaneBlack.position.x = parameters.planeSize * -0.5 - parameters.cubeSize

    for (let i = 0; i < objects.length; i++) {
        scene.remove(objects[i])
    }
    objects.splice(0, objects.length)
    scene.add(rollOverMesh)
    scene.add(plane)
    scene.add(deathPlaneRed)
    scene.add(deathPlaneBlack)
    objects.push(plane)
    objects.push(deathPlaneRed)
    objects.push(deathPlaneBlack)
    scene.remove(gridHelper)
    gridHelper = new THREE.GridHelper(
        parameters.planeSize,
        parameters.gridSize, 
        parameters.gridColor,
        parameters.gridColor)
    scene.add(gridHelper)
    fillTheBoard()
}
scene.add(ambientLight)
scene.add(directionalLight)

/**
 * Clocks and ticks useful for animations
 */
 const clock = new THREE.Clock()
 let previousTime = 0
 
 const tick = () =>
 {
    // Update Time
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime

    // Update mixers
    for (let i=0; i < mixers.length; i++) {
        mixers[i] !== null ? mixers[i].update(deltaTime) : false
    }
        
    // Update controls
    orbitControls.update()

    // Render
    renderer.render( scene, camera );

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()